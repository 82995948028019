<template>
  <div v-if="isLoaded">
    <DocumentEditor v-if="document"
                    ref="documentEditor"
                    :document.sync="document"
                    :is-editable="isEditable"
                    class="doc-editor-wrapper"/>
    <Loading :is-active="isLoading"
             :is-full="true"/>
  </div>
  <div v-else
       class="loading-container">
    <Loading :is-active="true"/>
  </div>
</template>

<script>
import { b64ToUtf8 } from '@/utils/utils'
import DocumentEditor from '@/components/DocumentEditor'
import Loading from '@/components/Loading'

export default {
  name: 'Document',
  components: { DocumentEditor, Loading },
  props: {
    documentId: {
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    useAutoSave: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isLoaded: false,
      isLoading: false,
      document: '',
    }
  },
  methods: {
    async save () {
      if (this.checkIsDocumentChanged()) {
        this.isLoading = true
        const resp = await this.$store.dispatch('setInspectionDocument', {
          inspectionID: this.$route.params.inspectionID,
          documentID: this.documentId,
          document: this.$refs.documentEditor.content[0],
        })
        if (resp) {
          // success save
          this.$emit('saved')
          this.document = this.$refs.documentEditor.content[0]
        }
        this.isLoading = false
        return resp
      } else {
        this.$store.dispatch('showMessage', {
          msg: 'Nem módosította a dokumentumot, mentés előtt módosítás szükséges.',
        })
      }
    },
    checkIsDocumentChanged () {
      // eslint-disable-next-line no-prototype-builtins
      let isChanged = false
      if (this.$refs.documentEditor) {
        if (this.$refs.documentEditor.content[0] !== this.document) {
          isChanged = true
        }
      }
      return isChanged
    },
  },
  watch: {
    documentId: {
      handler: async function () {
        this.isLoaded = false
        // this.isEditable = false
        // if document has changes save document
        if (this.checkIsDocumentChanged()) {
          // TODO: user mentés, ne váltson nézetet ameddig el nem mentette
          // console.log(111)
          // await this.save()
          // console.log(1112)
        }
        const resp = await this.$store.dispatch('fetchInspectionDocument', {
          inspectionID: this.$route.params.inspectionID,
          documentID: this.documentId,
        })
        if (resp) {
          this.document = b64ToUtf8(resp.document)
        }

        this.isLoaded = true
      },
      deep: true,
      immediate: true,
    },
  },
  mounted () {
    if (this.useAutoSave) {
      setInterval(async () => {
        if (this.isEditable) {
          if (this.checkIsDocumentChanged()) {
            const resp = await this.$store.dispatch('setInspectionDocument', {
              inspectionID: this.$route.params.inspectionID,
              documentID: this.documentId,
              document: this.$refs.documentEditor.content[0],
            })
            if (resp) {
              this.document = this.$refs.documentEditor.content[0]
              this.$store.dispatch('showMessage', { msg: 'Dokumentum mentve', type: 'success' })
            }
          }
        }
      }, 30000)
    }
  },
}
</script>

<style scoped>
.doc-editor-wrapper {
  /*padding: 100px;*/
  width: 400px;
  /*background: red;*/
}

.loading-container {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
