<template>
  <div class="document-editor-container">
    <!-- Top bar -->
    <vue-file-toolbar-menu v-if="isEditable"
                           :content="menu"
                           class="bar"/>
    <!-- Document editor -->
    <!-- eslint-disable vue/no-v-model-argument -->
    <vue-document-editor v-if="isEditable"
                         class="editor"
                         ref="editor"
                         :content.sync="content"
                         :zoom="zoom"
                         :editable="false"
                         :page_format_mm="page_format_mm"
                         :page_margins="page_margins"
                         :display="display"/>
    <vue-document-editor v-else
                         class="editor"
                         ref="editor"
                         :content.sync="notEditableDocument"
                         :zoom="zoom"
                         :editable="false"
                         :page_format_mm="page_format_mm"
                         :page_margins="page_margins"
                         :display="display"/>
  </div>
</template>

<script>

import VueDocumentEditor from 'vue-document-editor'
import VueFileToolbarMenu from 'vue-file-toolbar-menu'

export default {
  name: 'DocumentEditor',
  components: {
    VueDocumentEditor,
    VueFileToolbarMenu,
  },
  props: {
    document: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      content: [],
      zoom: 1.0,
      zoom_min: 1.0,
      zoom_max: 1.0,
      page_format_mm: [210, 297],
      page_margins: '10mm 15mm',
      display: 'vertical', // ["grid", "vertical", "horizontal"]
      mounted: false, // will be true after this component is mounted
      undo_count: -1, // contains the number of times user can undo (= current position in content_history)
      content_history: [], // contains the content states for undo/redo operations
    }
  },
  created () {
    // Initialize gesture flags
    this.content.push(this.document)
    let startZoomGesture = false
    let startDistTouch = false
    let startZoomTouch = false
    // Manage ctrl+scroll zoom (or trackpad pinch)
    window.addEventListener('wheel', (e) => {
      if (e.ctrlKey) {
        e.preventDefault()
        this.zoom = Math.min(Math.max(this.zoom - e.deltaY * 0.01, this.zoom_min), this.zoom_max)
      }
    }, { passive: false })
    // Manage trackpad pinch on Safari
    window.addEventListener('gesturestart', (e) => {
      e.preventDefault()
      startZoomGesture = this.zoom
    })
    window.addEventListener('gesturechange', (e) => {
      e.preventDefault()
      if (!startZoomTouch) {
        this.zoom = Math.min(Math.max(startZoomGesture * e.scale, this.zoom_min), this.zoom_max)
      }
    })
    window.addEventListener('gestureend', () => {
      startZoomGesture = false
    })
    // Manage pinch to zoom for touch devices
    window.addEventListener('touchstart', (e) => {
      if (e.touches.length === 2) {
        e.preventDefault()
        startDistTouch = Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY,
        )
        startZoomTouch = this.zoom
      }
    }, { passive: false })
    window.addEventListener('touchmove', (e) => {
      if (startDistTouch && startZoomTouch) {
        e.preventDefault()
        const zoom = startZoomTouch * Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY,
        ) / startDistTouch
        this.zoom = Math.min(Math.max(zoom, this.zoom_min), this.zoom_max)
      }
    }, { passive: false })
    window.addEventListener('touchend', () => {
      startDistTouch = false
      startZoomTouch = false
    }, { passive: false })
    // Manage history undo/redo events
    const manageUndoRedo = (e) => {
      switch (e && e.inputType) {
        case 'historyUndo':
          e.preventDefault()
          e.stopPropagation()
          this.undo()
          break
        case 'historyRedo':
          e.preventDefault()
          e.stopPropagation()
          this.redo()
          break
      }
    }
    window.addEventListener('beforeinput', manageUndoRedo)
    window.addEventListener('input', manageUndoRedo) // in case of beforeinput event is not implemented (Firefox)
    // If your component is susceptible to be destroyed, don't forget to
    // use window.removeEventListener in the Vue.js beforeUnmount handler
  },
  mounted () {
    this.mounted = true
  },
  computed: {
    notEditableDocument () {
      let modifiedDocument = this.document.replaceAll('contenteditable="true"', 'contenteditable="false"')
      modifiedDocument = modifiedDocument.replaceAll('onclick=""', 'onclick="return false;"')
      modifiedDocument = modifiedDocument.replaceAll(/onchange="[^"]*"/g, 'onchange="return false;"')
      return [modifiedDocument]
      // return [this.document.replaceAll('contenteditable="true"', 'contenteditable="false"')]
    },
    // This is the menu content
    menu () {
      return [
        {
          title: 'Undo',
          icon: 'undo',
          disabled: !this.can_undo,
          hotkey: this.isMacLike ? 'command+z' : 'ctrl+z',
          click: () => this.undo(),
        },
        {
          title: 'Redo',
          icon: 'redo',
          disabled: !this.can_redo,
          hotkey: this.isMacLike ? 'shift+command+z' : 'ctrl+y',
          click: () => this.redo(),
        },
        {
          icon: 'format_align_left',
          title: 'Align left',
          active: this.isLeftAligned,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? 'shift+command+l' : 'ctrl+shift+l',
          click: () => document.execCommand('justifyLeft'),
        },
        {
          icon: 'format_align_center',
          title: 'Align center',
          active: this.isCentered,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? 'shift+command+e' : 'ctrl+shift+e',
          click: () => document.execCommand('justifyCenter'),
        },
        {
          icon: 'format_align_right',
          title: 'Align right',
          active: this.isRightAligned,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? 'shift+command+r' : 'ctrl+shift+r',
          click: () => document.execCommand('justifyRight'),
        },
        {
          icon: 'format_align_justify',
          title: 'Justify content',
          active: this.isJustified,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? 'shift+command+j' : 'ctrl+shift+j',
          click: () => document.execCommand('justifyFull'),
        },
        { is: 'separator' },
        {
          icon: 'format_bold',
          title: 'Bold',
          active: this.isBold,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? 'command+b' : 'ctrl+b',
          click: () => document.execCommand('bold'),
        },
        {
          icon: 'format_italic',
          title: 'Italic',
          active: this.isItalic,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? 'command+i' : 'ctrl+i',
          click: () => document.execCommand('italic'),
        },
        {
          icon: 'format_underline',
          title: 'Underline',
          active: this.isUnderline,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? 'command+u' : 'ctrl+u',
          click: () => document.execCommand('underline'),
        },
        {
          icon: 'format_strikethrough',
          title: 'Strike through',
          active: this.isStrikeThrough,
          disabled: !this.current_text_style,
          click: () => document.execCommand('strikethrough'),
        },
        {
          is: 'button-color',
          type: 'compact',
          menu_class: 'align-center',
          disabled: !this.current_text_style,
          color: this.curColor,
          update_color: (newColor) => document.execCommand('foreColor', false, newColor.hex8),
        },
        { is: 'separator' },
        {
          icon: 'format_list_numbered',
          title: 'Numbered list',
          active: this.isNumberedList,
          disabled: !this.current_text_style,
          click: () => document.execCommand('insertOrderedList'),
        },
        {
          icon: 'format_list_bulleted',
          title: 'Bulleted list',
          active: this.isBulletedList,
          disabled: !this.current_text_style,
          click: () => document.execCommand('insertUnorderedList'),
        },
        {
          icon: 'format_clear',
          title: 'Clear format',
          disabled: !this.current_text_style,
          click () {
            document.execCommand('removeFormat')
            document.execCommand('formatBlock', false, '<div>')
          },
        },
      ]
    },
    // Current text style management
    current_text_style () {
      return this.mounted ? this.$refs.editor.current_text_style : false
    },
    isLeftAligned () {
      return ['start', 'left', '-moz-left'].includes(this.current_text_style.textAlign)
    },
    isRightAligned () {
      return ['end', 'right', '-moz-right'].includes(this.current_text_style.textAlign)
    },
    isCentered () {
      return ['center', '-moz-center'].includes(this.current_text_style.textAlign)
    },
    isJustified () {
      return ['justify', 'justify-all'].includes(this.current_text_style.textAlign)
    },
    isBold () {
      const fontWeight = this.current_text_style.fontWeight
      return fontWeight && (parseInt(fontWeight) > 400 || fontWeight.indexOf('bold') === 0)
    },
    isItalic () {
      return this.current_text_style.fontStyle === 'italic'
    },
    isUnderline () { // text-decoration is not overridden by children, so we query the parent stack
      const stack = this.current_text_style.textDecorationStack
      return stack && stack.some(d => (d.indexOf('underline') === 0))
    },
    isStrikeThrough () { // text-decoration is not overridden by children, so we query the parent stack
      const stack = this.current_text_style.textDecorationStack
      return stack && stack.some(d => (d.indexOf('line-through') === 0))
    },
    isNumberedList () {
      return this.current_text_style.isList && this.current_text_style.listStyleType === 'decimal'
    },
    isBulletedList () {
      return this.current_text_style.isList && ['disc', 'circle'].includes(this.current_text_style.listStyleType)
    },
    isH1 () {
      return this.current_text_style.headerLevel === 1
    },
    isH2 () {
      return this.current_text_style.headerLevel === 2
    },
    isH3 () {
      return this.current_text_style.headerLevel === 3
    },
    curColor () {
      return this.current_text_style.color || 'transparent'
    },
    // Platform management
    isMacLike: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
    // Undo / redo flags
    can_undo () {
      return this.undo_count > 0
    },
    can_redo () {
      return this.content_history.length - this.undo_count - 1 > 0
    },
  },
  methods: {
    // Undo / redo functions examples
    undo () {
      if (this.can_undo) {
        this._mute_next_content_watcher = true
        this.content = this.content_history[--this.undo_count]
      }
    },
    redo () {
      if (this.can_redo) {
        this._mute_next_content_watcher = true
        this.content = this.content_history[++this.undo_count]
      }
    },
    resetContentHistory () {
      this.content_history = []
      this.undo_count = -1
    },
  },
  watch: {
    document: function (newVal) {
      this.content = [newVal]
    },
    content: {
      immediate: true,
      // Fill undo / redo history stack on user input
      handler (newContent) {
        if (!this._mute_next_content_watcher) { // only update the stack when content is changed by user input, not undo/redo commands
          this.content_history[++this.undo_count] = newContent
          this.content_history.length = this.undo_count + 1 // remove all redo items
        }
        this._mute_next_content_watcher = false
      },
    },
  },
}
</script>

<style lang="scss"
       scoped>
//@import "../assets/css/bulma-theme";

.editor {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  //background: $white-ter;
  height: 100%;

  ::v-deep .page {
    top: 0 !important;
    position: relative !important;
    left: 0 !important;
    //background: blue;
    //background: $white;
    box-shadow: var(--page-box-shadow, 0 1px 3px 1px rgba(60, 64, 67, .15));
    //-webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.8);
    //box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.8);

  }

  ::v-deep .content table td {
    border: none !important;
    padding: 0 !important;
  }

  ::v-deep *[contenteditable='true'] {
    background: lightyellow;
    transition: padding 0.05s linear;
  }
}

span[contenteditable]:focus {
  padding: 0 4px;
}

.document-editor-container {
  width: fit-content;
  min-width: 100%;
}

.bar {
  position: sticky;
  //justify-content: center;
  left: 0;
  top: 0;
  //width: calc(100vw - 16px);
  z-index: 1;
  background: rgba(248, 249, 250, 0.8);
  border-bottom: solid 1px rgb(248, 249, 250);
  backdrop-filter: blur(10px);
  --bar-button-active-color: #188038;
  --bar-button-open-color: #188038;
  --bar-button-active-bkg: #e6f4ea;
  --bar-button-open-bkg: #e6f4ea;
}
</style>
